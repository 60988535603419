import './App.css'

// Import React dependencies.
import React, { useCallback, useMemo, useState } from 'react'
// Import the Slate editor factory.
import { createEditor, Editor } from 'slate'

// Import the Slate components and React plugin.
import { Slate, Editable, withReact, RenderLeafProps, RenderElementProps } from 'slate-react'
import { Element as SlateElement } from "slate"

import EditorLeaf from './components/EditorLeaf'
import EditorElement from './components/EditorElement'

// Slate types
import { Descendant } from 'slate'


const App = () => {

    const [initialValue] = useState<Descendant[]>(
        [
            {
                type: 'paragraph',
                children: [
                    {
                        type: 'sentence', children: [
                            { text: 'X' }
                        ],
                        flesch: null,
                        lastText: null
                    }],
            }
        ]
    )

    const withSentences = (editor: Editor) => {
        const { isInline, isVoid } = editor

        const customElementBehavior: { [key: string]: { [key: string]: boolean; } } = {
            "paragraph": { isInline: false, isVoid: false },
            "sentence": { isInline: false, isVoid: false }
        }
        editor.isInline = (element: SlateElement) => {
            if (element.type in customElementBehavior) {
                return customElementBehavior[element.type]['isInline']
            } else {
                return isInline(element)
            }
        }

        editor.isVoid = (element: SlateElement) => {
            if (element.type in customElementBehavior) {
                return customElementBehavior[element.type]['isVoid']
            } else {
                return isVoid(element)
            }
        }
        /*
                editor.isVoid = (element: SlateElement) => {
                    if (['paragraph'].includes(element.type)) {
                        return false
                    } else if(['sentence'].includes(element.type)) {
                        return true
                    } else {
                return isVoid(element)
            }
        } */

        return editor
    }

    const editor = useMemo(
        () => withSentences(withReact(createEditor())),
        []
    )

    /*
        const maybeFetchTextStats = useDebounce(async (editor: Editor) => {
            await fetchTextStats(editor)
        }, 2000)
    */
    const renderElement = useCallback((props: RenderElementProps) => <EditorElement {...props}
                                                                                    children={props.children}/>, [])
    const renderLeaf = useCallback((props: RenderLeafProps) => <EditorLeaf {...props} children={props.children}/>, [])

    return (
        <div className="app">
            <Slate
                editor={editor}
                initialValue={initialValue}
                onChange={async changedParagraphs => {
                    // see https://docs.slatejs.org/walkthroughs/06-saving-to-a-database
                    const isAstChange = editor.operations.some(
                        op => 'set_selection' !== op.type
                    )
                    if (isAstChange) {
                        /*
                        for (const paragraph of changedParagraphs){
                            console.log("CHANGE")

                            if('children' in paragraph){
                                for(const sentence of paragraph.children){
                                    console.log(sentence)
                                    if('flesch' in sentence) {
                                        delete sentence.flesch
                                    }
                                }
                            }
                        }
                         */
                    }
                }}
            >
                <Editable
                    renderElement={renderElement}
                    renderLeaf={renderLeaf}
                />
            </Slate>
        </div>
    )
}

export default App

import React, { FunctionComponent, useEffect, useRef, useState } from 'react'
import { Node, NodeEntry, Path, Transforms } from 'slate'
import { ReactEditor, useSlate } from 'slate-react'
import { SentenceElementProps } from '../types/custom-types'
import { SentenceElement as SlateSentenceElement } from '../types/custom-types'

const colorOfFlesch = (fleschValue: number | null) => {
    if (fleschValue) {
        if (fleschValue < 50) {
            return 'rgb(200,100,100)'
        } else if (fleschValue < 60) {
            return 'rgb(255,150,150)'
        } else if (fleschValue < 70) {
            return 'rgb(255,170,170)'
        } else if (fleschValue < 80) {
            return 'rgb(230,230,255)'
        } else if (fleschValue < 90) {
            return 'rgb(200,255,255)'
        } else if (fleschValue < 100) {
            return 'rgb(200,230, 200 )'
        } else if (fleschValue < 100) {
            return 'rgb(200,255,200 )'
        }
    } else {
        return 'rgb(255,255,255)'
    }
}

export const SentenceElement: FunctionComponent<SentenceElementProps> = ({ attributes, children, element }) => {
    const [flesch, setFlesch]: [number, React.Dispatch<React.SetStateAction<number>>] = useState(element.flesch);
    const lastText: React.MutableRefObject<string> = useRef(element.lastText)
    const refreshInfoTimeout: React.MutableRefObject<ReturnType<typeof setTimeout> | null> = useRef(null)

    const editor = useSlate()

    const DEBOUNCE_REFRESH_INFO_MS = 1000

    useEffect(() => {

        const refreshInfo = async () => {
            const text = getTextContent(element)
            if (text === "" || text.trim() === lastText.current?.trim()) {
                return false
            }

            const url = process.env.REACT_APP_TEXT_COMPLEXITY_API_URL + "/flesch"

            const fetchResponse = await fetch(url, {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({ texts: [text] })
            })
            const responseMetrics = await fetchResponse.json()
            const elementMeasurement = responseMetrics[0]
            if (elementMeasurement.sentence_indexes.length > 1) {
                // Splitting this sentence node
                const textPath: Path = ReactEditor.findPath(editor, element.children.at(-1));
                const ranges = elementMeasurement.sentence_indexes.map((start_end_tuple: number[]) => (
                    {
                        anchor: {
                            path: textPath,
                            offset: start_end_tuple[0]
                        },
                        focus: {
                            path: textPath,
                            offset: start_end_tuple[1]
                        }
                    }
                ))

                // first split: last sentence beginning
                for (let rangeIndex = ranges.length - 1; rangeIndex >= 0; rangeIndex--) {

                    const splitPath = ranges[rangeIndex].anchor.path
                    const splitOffset = ranges[rangeIndex].anchor.offset

                    if (splitOffset < 0) {
                        continue
                    }
                    Transforms.splitNodes(editor, {
                        at: { path: splitPath, offset: splitOffset }
                    })

                    const ancestors = Node.ancestors(editor, splitPath)
                    const ancestors_array = Array.from(ancestors)

                    const paragraph = ancestors_array[ancestors_array.length - 2] as NodeEntry<Node>
                    const sentence1 = ancestors_array[ancestors_array.length - 1] as NodeEntry<Node>

                    const paragraphChildren = Array.from(Node.children(paragraph[0], []))

                    if (sentence1[1].length === 0) {
                        throw new Error('Assertion failed: sentence1 not found')
                    }
                    const sentence1Index = sentence1[1].at(-1) || 0
                    const sentence2Index = sentence1Index + 1

                    const sentence2 = [
                        paragraphChildren[sentence2Index][0] as Node,
                        paragraph[1].concat(paragraphChildren[sentence2Index][1]) as Path
                    ] as NodeEntry

                    if ((sentence1[0] as SlateSentenceElement).type !== 'sentence') {
                        throw new Error('Assertion failed: not a sentence')
                    }

                    const sentenceFlesh = parseFloat(elementMeasurement.flesch_per_sentences[rangeIndex])
                    const sentenceText = text.slice(splitOffset, ranges[rangeIndex].focus.offset).trim()

                    if (rangeIndex === 0) {
                        lastText.current = sentenceText
                        setFlesch(sentenceFlesh)
                    }

                    if (Node.string(sentence1[0]).trim() === sentenceText) {
                        Transforms.setNodes(editor,
                            { flesch: sentenceFlesh, lastText: sentenceText },
                            { at: sentence1[1] })
                    }

                    if (Node.string(sentence2[0]).trim() === sentenceText) {
                        Transforms.setNodes(editor,
                            { flesch: sentenceFlesh, lastText: sentenceText },
                            { at: sentence2[1] })
                    }
                }
            } else {
                lastText.current = text
                setFlesch(elementMeasurement.flesch_per_sentences[0])
            }
        }

        // https://devtrium.com/posts/async-functions-useeffect
        const refreshInfoWrapper = async () => {
            await refreshInfo()
        }

        // debouncing
        if (refreshInfoTimeout.current !== null) {
            clearTimeout(refreshInfoTimeout.current)
            refreshInfoTimeout.current = null
        }
        refreshInfoTimeout.current = setTimeout(
            function () {
                refreshInfoTimeout.current = null
                refreshInfoWrapper().catch(console.error)
            }, DEBOUNCE_REFRESH_INFO_MS
        )

        // cleanup method
        return () => {
            if (refreshInfoTimeout.current !== null) {
                clearTimeout(refreshInfoTimeout.current)
                refreshInfoTimeout.current = null
            }
        }
    }, [element, editor]);

    const getTextContent = (node: Node): string => {
        return Node.string(node)
    };

    return (
        <span
            {...attributes}
            style={{
                marginLeft: '0.5rem',
                borderLeft: '0.05rem solid black',
                backgroundColor: flesch ? colorOfFlesch(flesch) : 'transparent'
            }}
            title={flesch}
        >
        {children}
        </span>
    )
}

export default SentenceElement

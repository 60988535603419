import React, { FunctionComponent } from "react";
import { ElementProps } from '../types/element-props'

export const ParagraphElement: FunctionComponent<ElementProps> = ({ attributes, children, element }) => {
    return (
        <span
            style={{borderTop: '1px solid black', margin: '0.5rem', backgroundColor: '#f0f0f0'}}
            {...attributes}
        >
        {children}
        </span>
    );
};

export default ParagraphElement
